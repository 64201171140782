export const SaveIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Uložiť</title>
<path d="M4 3H18L20.7071 5.70711C20.8946 5.89464 21 6.149 21 6.41421V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM7 4V9H16V4H7ZM6 12V19H18V12H6ZM13 5H15V8H13V5Z" fill="rgba(255,255,255,1)">
</path>
</svg>;}

export const SaveAndCloseIcon = () => {return <svg focusable="false" 
iconid="icon-save-and-close-2" viewBox="0 0 20 20"
width="24" height="24">
<title id="title">Uložiť a zavrieť</title>
<path fill="rgba(255,255,255,1)" fillRule="evenodd" clipRule="evenodd" d="M4.27734,6.3335h3.88965c0.21387,0,0.38867-0.1748,0.38867-0.38916V4.38867
C8.55566,4.17334,8.38086,4,8.16699,4H4.27734C4.06348,4,3.88867,4.17334,3.88867,4.38867v1.55566
C3.88867,6.15869,4.06348,6.3335,4.27734,6.3335L4.27734,6.3335z M10.11133,14.10254c0-0.4248-0.34473-0.76953-0.77051-0.76953
H4.65918c-0.42578,0-0.77051,0.34473-0.77051,0.76953v0.0166c0,0.42383,0.34473,0.76953,0.77051,0.76953h4.68164
c0.42578,0,0.77051-0.3457,0.77051-0.76953V14.10254z M10.11133,11.76953C10.11133,11.34375,9.7666,11,9.34082,11H4.65918
c-0.42578,0-0.77051,0.34375-0.77051,0.76953v0.01563c0,0.4248,0.34473,0.77051,0.77051,0.77051h4.68164
c0.42578,0,0.77051-0.3457,0.77051-0.77051V11.76953z M12.44434,16.05566c0,0.21289-0.1748,0.38867-0.38867,0.38867H1.94434
c-0.21387,0-0.38867-0.17578-0.38867-0.38867V9.8335c0-0.21582,0.1748-0.38916,0.38867-0.38916h10.11133
c0.21387,0,0.38867,0.17334,0.38867,0.38916V16.05566z M14,9v7.05566C14,17.12891,13.12891,18,12.05566,18H1.94434
C0.87109,18,0,17.12891,0,16.05566V5.9458C0,4.87061,0.87109,4,1.94434,4h0.13379C2.05664,4.12451,2,4.25537,2,4.38867
C2,4.77783,2,5.9458,2,5.9458C2,7.01709,3.28223,8,4.35645,8h3.81055C8.60156,8,9,7.74121,9,7.5v0.38867
C9,8.31592,9.68359,9,10.1123,9H14z"></path>
<path fill="rgba(255,255,255,1)" fillRule="evenodd" clipRule="evenodd" d="M17.70605,0.29297c-0.39063-0.39063-1.02246-0.39063-1.41406,0l-1.79297,1.79297
l-1.79297-1.79297c-0.39063-0.39063-1.02246-0.39063-1.41406,0c-0.39063,0.39111-0.39063,1.02344,0,1.41406L13.08496,3.5
l-1.79297,1.79297c-0.39063,0.39063-0.39063,1.02344,0,1.41406C11.4873,6.90234,11.74316,7,11.99902,7
s0.5127-0.09766,0.70703-0.29297l1.79297-1.79297l1.79297,1.79297C16.4873,6.90234,16.74316,7,16.99902,7
s0.5127-0.09766,0.70703-0.29297c0.3916-0.39063,0.3916-1.02344,0-1.41406L15.91309,3.5l1.79297-1.79297
C18.09766,1.31641,18.09766,0.68408,17.70605,0.29297"></path>
</svg>;}

export const AddIcon = () => {return  <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Pridať nový</title>
<path d="M15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918ZM11 11V8H13V11H16V13H13V16H11V13H8V11H11Z" fill="rgba(255,255,255,1)">
</path>
</svg>;}

export const EditIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Upraviť</title>
<path d="M21 6.75736L19 8.75736V4H10V9H5V20H19V17.2426L21 15.2426V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5501 3 20.9932V8L9.00319 2H19.9978C20.5513 2 21 2.45531 21 2.9918V6.75736ZM21.7782 8.80761L23.1924 10.2218L15.4142 18L13.9979 17.9979L14 16.5858L21.7782 8.80761Z" fill="rgba(255,255,255,1)">
</path>
</svg>;}

export const CopyIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Kopírovať</title>
<path d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM5.00242 8L5.00019 20H14.9998V8H5.00242ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const DeleteIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Vymazať</title>
<path d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const StepBackIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Krok späť</title>
<path d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const StepForwardIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Krok vpred</title>
<path d="M18.1716 6.99955H11C7.68629 6.99955 5 9.68584 5 12.9996C5 16.3133 7.68629 18.9996 11 18.9996H20V20.9996H11C6.58172 20.9996 3 17.4178 3 12.9996C3 8.58127 6.58172 4.99955 11 4.99955H18.1716L15.636 2.46402L17.0503 1.0498L22 5.99955L17.0503 10.9493L15.636 9.53509L18.1716 6.99955Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const PrintIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Tlačiť</title>
<path d="M17 2C17.5523 2 18 2.44772 18 3V7H21C21.5523 7 22 7.44772 22 8V18C22 18.5523 21.5523 19 21 19H18V21C18 21.5523 17.5523 22 17 22H7C6.44772 22 6 21.5523 6 21V19H3C2.44772 19 2 18.5523 2 18V8C2 7.44772 2.44772 7 3 7H6V3C6 2.44772 6.44772 2 7 2H17ZM16 17H8V20H16V17ZM20 9H4V17H6V16C6 15.4477 6.44772 15 7 15H17C17.5523 15 18 15.4477 18 16V17H20V9ZM8 10V12H5V10H8ZM16 4H8V7H16V4Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const SearchIcon = ({color}) => {
  if(color==="active"){
    return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Prehľadávať</title>
<path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="rgba(25,118,210,1)">
</path></svg>;
  }
  else {
    return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Prehľadávať</title>
<path d="M18.031 16.6168L22.3137 20.8995L20.8995 22.3137L16.6168 18.031C15.0769 19.263 13.124 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2C15.968 2 20 6.032 20 11C20 13.124 19.263 15.0769 18.031 16.6168ZM16.0247 15.8748C17.2475 14.6146 18 12.8956 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18C12.8956 18 14.6146 17.2475 15.8748 16.0247L16.0247 15.8748Z" fill="rgba(255,255,255,1)">
</path></svg>;
  }
}

export const ExportIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Exportovať</title>
<path d="M2.85858 2.87756L15.4293 1.08175C15.7027 1.0427 15.9559 1.23265 15.995 1.50601C15.9983 1.52943 16 1.55306 16 1.57672V22.4237C16 22.6999 15.7761 22.9237 15.5 22.9237C15.4763 22.9237 15.4527 22.922 15.4293 22.9187L2.85858 21.1229C2.36593 21.0525 2 20.6306 2 20.1329V3.86751C2 3.36986 2.36593 2.94794 2.85858 2.87756ZM4 4.73481V19.2656L14 20.6942V3.30624L4 4.73481ZM17 19.0002H20V5.00022H17V3.00022H21C21.5523 3.00022 22 3.44793 22 4.00022V20.0002C22 20.5525 21.5523 21.0002 21 21.0002H17V19.0002ZM10.2 12.0002L13 16.0002H10.6L9 13.7145L7.39999 16.0002H5L7.8 12.0002L5 8.00022H7.39999L9 10.2859L10.6 8.00022H13L10.2 12.0002Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const VerticalLineIcon = () => {return <svg 
width="24" height="24" 
viewBox="0 0 24 24" 
fill="none" 
xmlns="http://www.w3.org/2000/svg"> 
<path d="M13 2H11V22H13V2Z" fill="grey"/> 
</svg>;}

export const BackToFirstIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Prvý</title>
<path d="M7 18V6H9V18H7ZM11 12 17 6V18L11 12Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const BackIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Predchádzajúci</title>
<path d="M8 12L14 6V18L8 12Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const ForwardIcon = () => {return<svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Nasledujúci</title>
<path d="M16 12L10 18V6L16 12Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const ForwardToLastIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Posledný</title>
<path d="M17 6 17 18H15L15 6 17 6ZM13 12 7 18V6L13 12Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const FavoriteIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Pridať do Obľúbených</title>
<path d="M4 2H20C20.5523 2 21 2.44772 21 3V22.2763C21 22.5525 20.7761 22.7764 20.5 22.7764C20.4298 22.7764 20.3604 22.7615 20.2963 22.7329L12 19.0313L3.70373 22.7329C3.45155 22.8455 3.15591 22.7322 3.04339 22.4801C3.01478 22.4159 3 22.3465 3 22.2763V3C3 2.44772 3.44772 2 4 2ZM12 13.5L14.9389 15.0451L14.3776 11.7725L16.7553 9.45492L13.4695 8.97746L12 6L10.5305 8.97746L7.24472 9.45492L9.62236 11.7725L9.06107 15.0451L12 13.5Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const PriceIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
viewBox="0 0 24 24"
width="24" height="24" >
<title id="title">Pridať do Rýchleho náhľadu</title>
<path d="M17 15.2454V22.1169C17 22.393 16.7761 22.617 16.5 22.617C16.4094 22.617 16.3205 22.5923 16.2428 22.5457L12 20L7.75725 22.5457C7.52046 22.6877 7.21333 22.6109 7.07125 22.3742C7.02463 22.2964 7 22.2075 7 22.1169V15.2454C5.17107 13.7793 4 11.5264 4 9C4 4.58172 7.58172 1 12 1C16.4183 1 20 4.58172 20 9C20 11.5264 18.8289 13.7793 17 15.2454ZM12 15C15.3137 15 18 12.3137 18 9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9C6 12.3137 8.68629 15 12 15ZM12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9C16 11.2091 14.2091 13 12 13Z" fill="rgba(255,255,255,1)">
</path></svg>;}

export const CalendarIcon = () => {return <svg 
xmlns="http://www.w3.org/2000/svg" 
width="24" height="24"
viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line>
<title id="title">Kalendár</title>
</svg>
}

export const LinkIcon = () =>{
    return <svg 
    className="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" 
    viewBox="0 0 24 24">
    <path stroke="blue" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"/>
  </svg>
}

export const PaperClipIcon = () =>{
  return <svg 
  xmlns="http://www.w3.org/2000/svg" 
  width="24" height="24" 
  viewBox="0 0 24 24" fill="none" stroke="#009BCE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
    </svg>
}

export const ProblemIcon = ({color}) =>{
  switch (color){
    case 'red': return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="#ff0000" stroke="#ff0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>;
    case 'green': return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="#037d50" stroke="#037d50" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>;
    case 'blue': return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="#0000ff" stroke="#0000ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>;
    case 'orange': return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="#ffa500" stroke="#ffa500" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>;
    case 'magenta': return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="#ff00ff" stroke="#ff00ff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>;
    default: return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
  }
}

export const EditRowIcon = () =>{
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><path d="M12 8l4 4-4 4M8 12h7"/></svg>;
}

export const InactiveCopyIcon = () => {
  return <svg className="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
  <path fill-rule="evenodd" d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a2 2 0 0 0-1 .3V5c0-1.1.9-2 2-2h7Z" clip-rule="evenodd"/>
  <path fill-rule="evenodd" d="M8 7v4H4.2c0-.2.2-.3.3-.4l2.4-2.9A2 2 0 0 1 8 7.1Zm2 0v4a2 2 0 0 1-2 2H4v6c0 1.1.9 2 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z" clip-rule="evenodd"/>
</svg>
}

export const ActiveCopyIcon = () => {
  return <svg className="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m5 12 4.7 4.5 9.3-9"/>
</svg>
}

export const LoadPrioIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
  fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" >
  <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3"/></svg>
}

export const SolverIcon = () => {
  return <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  <path stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.5 4h-13m13 16h-13M8 20v-3.3c0-.5.1-.9.4-1.2l1.6-2.9a1 1 0 0 0 0-1.2L8.4 8.5A2 2 0 0 1 8 7.3V4h8v3.3c0 .5-.1.9-.4 1.2L14 11.4a1 1 0 0 0 0 1.2l1.6 2.9c.3.3.4.7.4 1.2V20H8Z"/>
</svg>
}

export const ContractorIcon = () => {
  return <svg className="w-5 h-5 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  <path stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m10.8 17.8-6.4 2.1 2.1-6.4m4.3 4.3L19 9a3 3 0 0 0-4-4l-8.4 8.6m4.3 4.3-4.3-4.3m2.1 2.1L15 9.1m-2.1-2 4.2 4.2"/>
</svg>
}

export const RefreshIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.2"/>
    <title id="title">Aktualizovať</title>
    </svg>;
}

export const ShowPasswordIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 12c0 1.2-4 6-9 6s-9-4.8-9-6c0-1.2 4-6 9-6s9 4.8 9 6Z"/>
    <path d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
    <title id="title">Zobraziť heslo</title>
    </svg>;
}

export const HidePasswordIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M4 14c-.5-.6-.9-1.3-1-2 0-1 4-6 9-6m7.6 3.8A5 5 0 0 1 21 12c0 1-3 6-9 6h-1m-6 1L19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
    <title id="title">Skryť heslo</title>
    </svg>;
}

export const PrioBack = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" 
  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7f1d1d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M19 12H6M12 5l-7 7 7 7"/></svg>
}

export const CloseMess = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" 
  width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#696CFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
}
export const InfoIcon = ({width,height}) => {
  return <svg class={`w-${width} h-${height} text-gray-800 dark:text-white mr-2`} aria-hidden="true" 
        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#8B0000" viewBox="0 0 24 24">
    <title id="title">Info</title>
    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm9.408-5.5a1 1 0 1 0 0 2h.01a1 1 0 1 0 0-2h-.01ZM10 10a1 1 0 1 0 0 2h1v3h-1a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-1v-4a1 1 0 0 0-1-1h-2Z" clip-rule="evenodd"/>
  </svg>
}

export const ErrorIcon = ({width,height}) => {
  return <svg class={`w-${width} h-${height} text-gray-800 dark:text-white mr-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <title id="title">Chyba</title>
  <path stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5 9 4V3m5 2 1-1V3m-3 6v11m0-11a5 5 0 0 1 5 5m-5-5a5 5 0 0 0-5 5m5-5a4.959 4.959 0 0 1 2.973 1H15V8a3 3 0 0 0-6 0v2h.027A4.959 4.959 0 0 1 12 9Zm-5 5H5m2 0v2a5 5 0 0 0 10 0v-2m2.025 0H17m-9.975 4H6a1 1 0 0 0-1 1v2m12-3h1.025a1 1 0 0 1 1 1v2M16 11h1a1 1 0 0 0 1-1V8m-9.975 3H7a1 1 0 0 1-1-1V8"/>
</svg>

  }

export const TaskIcon = ({width,height}) => {
  return <svg class={`w-${width} h-${height} text-gray-800 dark:text-white mr-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000" viewBox="0 0 24 24">
    <title id="title">Úloha</title>
    <path fill-rule="evenodd" d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z" clip-rule="evenodd"/>
    <path fill-rule="evenodd" d="M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z" clip-rule="evenodd"/>
  </svg>
  }

export const PermissionIcon = ({width,height}) => {
return <svg class={`w-${width} h-${height} text-gray-800 dark:text-white mr-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000" viewBox="0 0 24 24">
<title id="title">Povolenie</title>
<path fill-rule="evenodd" d="M12 6a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Zm-1.5 8a4 4 0 0 0-4 4 2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-3Zm6.82-3.096a5.51 5.51 0 0 0-2.797-6.293 3.5 3.5 0 1 1 2.796 6.292ZM19.5 18h.5a2 2 0 0 0 2-2 4 4 0 0 0-4-4h-1.1a5.503 5.503 0 0 1-.471.762A5.998 5.998 0 0 1 19.5 18ZM4 7.5a3.5 3.5 0 0 1 5.477-2.889 5.5 5.5 0 0 0-2.796 6.293A3.501 3.501 0 0 1 4 7.5ZM7.1 12H6a4 4 0 0 0-4 4 2 2 0 0 0 2 2h.5a5.998 5.998 0 0 1 3.071-5.238A5.505 5.505 0 0 1 7.1 12Z" clip-rule="evenodd"/>
</svg>

}

export const NeedIcon = ({width,height}) => {
  return <svg class={`w-${width} h-${height} text-gray-800 dark:text-white mr-2`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000" viewBox="0 0 24 24">
  <title id="title">Potreba</title>
  <path fill-rule="evenodd" d="M14 7h-4v3a1 1 0 0 1-2 0V7H6a1 1 0 0 0-.997.923l-.917 11.924A2 2 0 0 0 6.08 22h11.84a2 2 0 0 0 1.994-2.153l-.917-11.924A1 1 0 0 0 18 7h-2v3a1 1 0 1 1-2 0V7Zm-2-3a2 2 0 0 0-2 2v1H8V6a4 4 0 0 1 8 0v1h-2V6a2 2 0 0 0-2-2Z" clip-rule="evenodd"/>
</svg>

  }

export const  EmergencyIcon = ({width,height}) => {
return  <svg class={`w-${width} h-${height} mr-2`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ce0000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <title id="title">Núdzová správa</title>
  <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
}

export const BellSlashIcon = () => {
  return <svg fill="#000000" width="24" height="24" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M 39.9415 33.7891 L 48.3323 42.3672 C 48.7072 41.8984 48.9179 41.3359 48.9179 40.6797 C 48.9179 38.8047 46.9728 37.0469 45.2852 35.3594 C 43.3634 33.2031 42.6603 30.8828 42.5431 27.5313 C 42.4259 17.5703 39.9415 11.5469 33.6603 9.2734 C 32.7931 5.9922 30.1447 3.7891 26.9103 3.7891 C 23.6525 3.7891 21.0274 5.9922 20.1368 9.2734 C 18.8712 9.6953 17.7931 10.2813 16.8556 11.0313 L 19.5509 13.7734 C 20.3947 13.1875 21.3321 12.6953 22.3400 12.4375 C 22.9259 12.2734 23.2306 11.9922 23.2540 11.4062 C 23.3478 8.9453 24.7306 7.2344 26.9103 7.2344 C 29.0431 7.2344 30.4728 8.9453 30.5431 11.4062 C 30.5665 11.9922 30.8947 12.2969 31.4806 12.4375 C 35.5821 13.4687 38.6525 16.9375 38.8165 27.625 C 38.9337 30.4844 39.1681 32.2187 39.9415 33.7891 Z M 47.9571 51.2031 C 48.6602 51.9062 49.8088 51.9062 50.5120 51.2031 C 51.2147 50.5234 51.2147 49.3516 50.5120 48.6484 L 9.2852 7.4687 C 8.5821 6.7656 7.4103 6.7656 6.7071 7.4687 C 6.0274 8.1484 6.0274 9.3203 6.7071 10.0234 Z M 8.3243 43.7500 L 31.9259 43.7500 C 31.7618 46.9141 29.7228 48.9297 26.9103 48.9297 C 24.0743 48.9297 22.0587 46.9141 21.8712 43.7500 L 18.2150 43.7500 C 18.4025 48.2500 21.9415 52.2109 26.9103 52.2109 C 31.6915 52.2109 35.1603 48.5078 35.5352 44.1719 L 35.1134 43.7500 L 36.6368 43.7500 L 33.0978 40.2109 L 9.3556 40.2109 L 9.3556 39.9297 C 9.7774 39.2266 11.1603 37.8672 12.4025 36.4844 C 14.0665 34.6094 14.8634 31.6094 15.0743 27.0156 C 15.1212 25.3516 15.2384 23.875 15.4259 22.5391 L 12.1447 19.2578 C 11.6525 21.4375 11.3947 23.9219 11.3478 26.7344 C 11.1603 29.4297 10.7384 32.7578 9.1915 34.375 C 7.1759 36.4609 4.7853 38.5703 4.7853 40.9141 C 4.7853 42.6250 6.0978 43.7500 8.3243 43.7500 Z"></path></g></svg>;
}

export const CustomerServiceIcon = () => {
  return <svg fill="none" stroke="#9ca3af" stroke-miterlimit="10" stroke-width="1.91px" className="hover:stroke-[#555]" width="24px" height="24px" viewBox="0 0 24 24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
    <title id="title">Podpora</title>
    <rect  x="6.27" y="5.32" width="11.45" height="15.27" rx="5.73"/><path  d="M17.73,9.14h1.91A2.86,2.86,0,0,1,22.5,12v1.91a2.86,2.86,0,0,1-2.86,2.86H17.73a0,0,0,0,1,0,0V9.14A0,0,0,0,1,17.73,9.14Z"/><path  d="M1.5,9.14H3.41A2.86,2.86,0,0,1,6.27,12v1.91a2.86,2.86,0,0,1-2.86,2.86H1.5a0,0,0,0,1,0,0V9.14A0,0,0,0,1,1.5,9.14Z" transform="translate(7.77 25.91) rotate(180)"/><path  d="M4.36,9.14h0A7.64,7.64,0,0,1,12,1.5h0a7.64,7.64,0,0,1,7.64,7.64h0"/><path  d="M19.64,16.77v1a4.78,4.78,0,0,1-4.78,4.77"/></svg>
}

export const OutlookMsgIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>;
}

export const ActivateIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <title id="title">Aktivovať</title>
    <circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg>;
}

export const DeactivateIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <title id="title">Deaktivovať</title>
    <circle cx="12" cy="12" r="10"></circle><rect x="9" y="9" width="6" height="6"></rect></svg>;
}

export const CopyLinkIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <title id="title">Kopírovať link</title>
    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>;
}


