import React,{useContext,useEffect, useState, useRef} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon,DeleteIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, PaperClipIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import AuthContext from '../../components/AuthContext';
import UtilContext from "../../components/UtilContext";
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { Toast } from 'primereact/toast';

export default function DocumentationAdd({measure,name,type}) {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,visible} = useContext(UtilContext);

    const [recordProcessorDepartment, setRecordProcessorDepartment] = useState();
    const [recordProcessors, setRecordProcessors] = useState([]);
    const [images,setImages] = useState('');
    const [viewedImage,setViewedImage] = useState('');

    const [formDocumentation,setFormDocumentation] = useState(measure);
    const [documentationRecordType, setDocumentationRecordType] = useState(type);
    const [fileList, setFileList] = useState([]);

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add otk-section1") || measure.contractor_id==user.employee.id)?false:true);
    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [scrollIcons,setScrollIcons] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);

    const [visibleFiles,setVisibleFiles] = useState(false);

    const [closeTab,setCloseTab] = useState(false);

    const submitRef = useRef(null);
    const componentRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });

    useEffect(() => {
        // Update the document title using the browser API
        if(formDocumentation!=null){
            filterRecordProcessors(formDocumentation.contractor.department_id);
        }
        if(type=="edit")getImages();
    },[formDocumentation]);

    function getImages(id){
        getRequest(`/api/measures/images/${id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
    }

    /* validate form */
    const validate = values => {
        const errors = {};
        return errors;
    };

    /* form for adding error record */
    const formik = useFormik({
        initialValues: formDocumentation==null?{
            article_number: '',order_number:'',contractor_id:'',type:name,
            total_amount:'',measure_complexity_id:'',
        }:formDocumentation,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }
            for(let i = 0;i<fileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',fileList[i]);
            }
            if(documentationRecordType=="add"){
                setDialogText("Ukladám");
                setVisible(true);
                postRequest('/api/measures', formData,true)
                .then((response) => {
                    formik.setValues(response.data);
                    //setDocumentationRecordType("edit");
                    let url = "";
                    if(name=="DOKUMENTÁCIA"){
                        url = "/api/measure/documentation";
                    }
                    else if(name=="SEBAKONTROLA"){
                        url = "/api/measure/selfControl";
                    }
                    else if(name=="MERACÍ PROTOKOL"){
                        url = "/api/measure/measurementProtocol";
                    }
                    else if(name=="NDT PROTOKOL"){
                        url = "/api/measure/NDTProtocol";
                    }
                    else if(name=="ŽÍHACÍ PROTOKOL"){
                        url = "/api/measure/annealingProtocol";
                    }
                    if(fileList.length){
                        getImages(response.data.id);
                        setFileList([]);
                        fileListRef.current.value = null;
                    }
                    setDialogText("Načítavam");
                    getRequest(url).then((response) => {
                        if(name=="DOKUMENTÁCIA"){
                            dispatch({type:'load_documentation',newDocumentation:response.data});
                        }
                        else if(name=="SEBAKONTROLA"){
                            dispatch({type:'load_self_control',newSelfControl: response.data});
                        }
                        else if(name=="MERACÍ PROTOKOL"){
                            dispatch({type:'load_measurement_protocol',newMeasurementProtocol: response.data});                        }
                        else if(name=="NDT PROTOKOL"){
                            dispatch({type:'load_ndt_protocol',newNDTProtocol: response.data});
                        }
                        else if(name=="ŽÍHACÍ PROTOKOL"){
                            dispatch({type:'load_annealing_protocol',newAnnealingProtocol: response.data});
                        }
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        setVisible(false);
                        if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                        else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    }).catch((reason) => {
                        setVisible(false);
                        alert("Daný úkon sa nepodaril!");
                    });
                }).catch((reason) => {
                    setVisible(false);
                    alert("Daný úkon sa nepodaril!");
                });
            }
            else{
                postRequest(`/api/measures/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/measures").then((response) => {
                        dispatch({type:'load_measures',newErrorRecords:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        dispatch({type:'measures_reload',value:true});
                        formik.setValues(values);
                        if(fileList.length){
                            getImages();
                            setFileList([]);
                            fileListRef.current.value = null;
                        }
                        if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                        else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };

    function addNewDocRecord(){
        dispatch({type:'create_new_tab',name:'Pridať dokumentáciu',page:'DocumentationAdd',props:{measure: null,name:'DOKUMENTÁCIA',type:'add',not_last_used: true},mask:'otk009'})
    }

    function copyDocRecord(){
        dispatch({type:'create_new_tab',name:'Pridať dokumentáciu',page:'DocumentationAdd',props:{measure: formik.values,name:'DOKUMENTÁCIA',type:'add',not_last_used: true},mask:'otk009'})
    }


    /* filter record processors by department in form */
    function filterRecordProcessors(departmentId){
        setRecordProcessorDepartment(departmentId);
        setRecordProcessors(state.employees
        .filter(employee => employee.department.id === parseInt(departmentId)));
    }

    function getIndex(error_record_id) {
        return state.error_records_int.findIndex(obj => obj.id === error_record_id);
    }

    async function scrollToFirst(){
        let my_error_record = state.error_records_int[0];
        setFormDocumentation(my_error_record);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormDocumentation(state.error_records_int[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.error_records_int.length){
            setFormDocumentation(state.error_records_int[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.error_records_int.length-1;
        let my_error_record = state.error_records_int[last];
        setFormDocumentation(my_error_record);
    }

      const accept = (id) => {
          getRequest(`/api/measures/deleteImage/${id}`)
              .then((response) => {
                toast?.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
                getImages();
              }).catch((reason) => {
                  alert(reason);
                  toast?.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
              })
        };

        const reject = () => {
            toast?.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
        }
    

    function deleteFiles(img){
        confirmDialog({
            message: 'Prajete si vymazať tento súbor?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => accept(img.id)
        });
    }

    return (
        <>
        <Toast ref={ownToast}/>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="saveandclose"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="save"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewDocRecord()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyDocRecord()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="print"><button onClick={() => handlePrint()}><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="back"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="forward"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Pridať dokumentáciu","Documentation Add")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button onClick={() => setFastView("DocumentationAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><VerticalLineIcon/></div>
          </IntersectionObserverWrapper>
        </div>
        <div className='overflow-y-auto h-[calc(100vh-177px)] bg-gray-100'>
        <form onSubmit={formik.handleSubmit}>
       <div className="relative flex flex-col justify-left overflow-x">
          <div className="xl:grid grid-cols-2 gap-1 mb-2 ">
          <div className={`w-auto p-6 m-4 bg-white rounded-md shadow-lg`}>
                <h1 className="text-2xl font-semibold text-left text-zinc-900">
                   {name}
                </h1>
                {documentationRecordType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                <div className="mt-6">
                    <div>
                      <div>
                        <label
                            htmlFor="article_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Artikel
                        </label>
                        <input
                            name='article_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.article_number}
                            className={`bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="order_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zákazka
                        </label>
                        <input
                            name='order_number' required
                            type="text"
                            onChange={formik.handleChange} value={formik.values.order_number}
                            className={`bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}
                        />
                        </div>
                    </div>
                    <div>
                    <div>
                        <label
                            htmlFor="contractor_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Meno zadávateľa
                        </label>
                        <select 
                        required
                        name="contractor_id"
                        onChange={formik.handleChange} value={formik.values.contractor_id}
                        className={` ${
                            user.permissions.includes("add otk-section2") ? "bg-white " : "bg-gray-200 "
                        } block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber zamestnanca</option>
                          {state.employees.map((recordProcessor) => (
                            <option key={recordProcessor.id} value={recordProcessor.id}>{recordProcessor.name}</option>
                            ))}
                        </select>
                        </div>
                    </div>
                {name=="DOKUMENTÁCIA" && <>
                <div className='grid grid-cols-2 gap-1'>
                    <div className='w-full mb-2'>
                    <label htmlFor='total_amount' className='font-semibold'>
                        Počet kusov:
                        <input 
                        onChange={formik.handleChange} value={formik.values.total_amount}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        type='number'
                        name='total_amount'></input>
                    </label>
                </div>
                <div className='w-full mb-2'>
                    <label htmlFor='measure_complexity_id' className='font-semibold'>
                        Náročnosť dielu:
                        </label>
                        <select required
                        onChange={formik.handleChange} value={formik.values.measure_complexity_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                        name="measure_complexity_id">
                            <option value="">Vyber náročnosť</option>
                            {state.measure_complexities.map((complexity) => (
                            <option key={complexity.id} value={complexity.id}>{complexity.category + " - " + complexity.description}</option>
                            ))}
                        </select>
                </div>
                </div>
                </>}
                <div>
                    <label
                        htmlFor="files"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Súbory:
                    </label>
                    <input
                    accept='image/*,application/pdf'
                    name='files' 
                    ref={fileListRef}
                    onChange={handleFileChange}
                    className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                    type='file'
                    multiple="multiple"
                    id='file'/>
                </div>
                {documentationRecordType=="edit" && <div>
                    <label
                        htmlFor="images"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Prílohy:
                    </label>
                    {images!=='' && images.map((img) => (<table className='w-full border border-separate border-gray-400 rounded-md bg-white mb-4'>
                        <tr>
                            <td className='text-black h-10'><div className='flex flex-row p-2'>
                                <div onClick={() => setVisibleFiles(true)}>
                                    <embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>
                                </div>
                                    <div className='flex flex-col ml-4'>
                                    <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                    <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/><span className='text-white ml-1'>Vymazať</span></button>
                                    </div>
                                </div></td>
                        </tr>
                    </table>))}
                    </div> }   
                </div>          
            </div>       
            </div>
            <div>
            <input
                name='type'
                type="hidden"
                value={formik.values.type}
            />
            <button type="submit" ref={submitRef} className='hidden'></button>
            {/*<button type="submit" className='w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>*/}
            </div>
        </div>
        </form>
        <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
        <iframe src={viewedImage.url} className='w-full h-full'></iframe>
        </Dialog>
        </div>
        </>
    );
}